<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable" v-if="visiable" width="80%">
		<div class="content">
			<div class="details pt-15">
				<div class="img"><img :src="photoURL + InfOjb.head" alt="" /></div>
				<div>
					<div class="name">{{ InfOjb.name }}</div>
					<div>城市：{{ InfOjb.city }}</div>
					<div>近一年服务次数：{{InfOjb.serviceNum}}</div>
					<div>是否掌握诊断技能：{{InfOjb.skillDetect==1?'是':'否'}}</div>
					<div>是否掌握维修技能：{{InfOjb.skillRepair ==1?'是':'否'}}</div>
				</div>
			</div>
			<div class="goodsInfo">
				<el-tabs type="border-card">
					<el-tab-pane label="专家详情">
						<!-- <img class="w-100" src="./img/details.png" /> -->
						<el-image
							class="my-15 ml-15"
							style="width: 100px; height: 100px"
							:src="item"
							:preview-src-list="imageList"
							v-for="(item, index) in imageList"
							:key="'img' + index"
						></el-image>
						<div class="px-15 word2 mb-30">
							<span class="word1">1.从业资格</span>
							<p>{{ skillObj.qualify }}</p>
							<span class="word1">2、职称</span>
							<p>{{ skillObj.rank }}</p>
							<span class="word1">2、擅长</span>
							<p>{{ skillObj.goodAt }}</p>
							<span class="word1">4、介绍</span>
							<p>{{ skillObj.introduce }}</p>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="goodsInfo">
				<el-tabs type="border-card">
					<el-tab-pane label="服务项目">
						<div class="border-card-item">
							<div class="border-card-item-title">服务项目</div>
							<div class="border-card-item-title">服务类型</div>
							<div class="border-card-item-title border-right">价格</div>
						</div>
						<div class="border-card-item" :class="i==serviceList.length-1?'border-bottom':''" v-for="(item,i) in serviceList" :key="i">
							<div >{{item.name}}</div>
							<div >{{item.skillType==1?'诊断服务':'维修服务'}}</div>
							<div class="border-right">{{item.price}}</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<div slot="footer" class="dialog-footer"><el-button @click="close()">关闭</el-button></div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '查看专家',
			formData: {},
			visiable: false,
			InfOjb: {},
			skillObj: {},
			imageList: [],
			serviceList:[]
		};
	},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			this.get();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		//获取数据
		get() {
			this.$get('/shop-api/mall/expert/detail?id=' + this.dialogObj.id).then(res => {
				if (res.code == 1000) {
					this.InfOjb = res.data.expertEo; //专家信息
					this.skillObj = res.data.skill; //诊断
					this.serviceList = res.data.serviceList;
					this.imageList = res.data.skill.attachmentList.map(i => this.photoURL + i); // 预览图片
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}

.content {
	padding: 10px;
	.details {
		background-color: #fffffd;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		line-height: 30px;
		.img {
			width: 150px;
			height: 150px;
			margin-bottom: 20px;
			margin-right: 20px;
			img {
				border-radius: 5%;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.name{
			color: #262626;
			font-size: 16px;
			font-weight: bold;
		}
		
	}
}
.goodsInfo {
	letter-spacing: 1px;
	margin-bottom: 10px;
	.title {
		height: 60px;
		line-height: 60px;
		background-color: #f5f5f5;
	}

	.word1 {
		color: #262626;
		font-size: 16px;
		font-weight: bold;
		padding-top: 15px;
	}

	.word2 {
		span {
			padding-top: 10px;
		}

		p {
			color: #595959;
			padding: 10px 0;
		}
	}
	.border-card-item{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		div{
			flex: 1;
			line-height: 40px;
			text-align: center;
			border-top:1px solid #333333;
			border-left:1px solid #333333;
		}
	}
	.border-card-item-title{
		font-weight: bold;
	}
	.border-right{
		border-right:1px solid #333333;
	}
	.border-bottom{
		border-bottom:1px solid #333333;
	}
}
</style>
