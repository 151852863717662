<template>
	<div class="flex" style="flex-direction: column;height: 100%;overflow: hidden;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="专家师傅："><el-input v-model="searchParm.keyword" placeholder=""></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-row>
							<el-form-item label="商品分类：">
								<el-col :span="12">
									<el-select v-model="searchParm.skillType" @change="cskillTypeChange" placeholder="服务分类：" >
										<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<el-table
						ref="usertable"
						:data="tableData.records"
						border
						:height="tableHeight"
						:header-cell-style="{ 'background-color': '#f9fafc' }"
						:cell-style="{ padding: 0 }"
						style="margin-bottom: 20px;"
						v-loading="isLoading"
					>
						<el-table-column align="center" label="序号" show-overflow-tooltip>
							<template slot-scope="scope">
								<div>{{ scope.$index + 1 }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="id" align="center" label="货号" show-overflow-tooltip></el-table-column>
						<el-table-column align="center" label="商品图片">
							<template slot-scope="scope">
								<img class="goodImg" :src="photoURL + scope.row.head" alt="" />
							</template>
						</el-table-column>
						<el-table-column prop="name" align="center" label="姓名" show-overflow-tooltip></el-table-column>
						<el-table-column prop="goodAt" align="center" label="擅长" show-overflow-tooltip>
						</el-table-column>
						<el-table-column fixed="right" align="center" label="操作" width="230">
							<template slot-scope="scope">
								<div style="min-height:40px">
									<el-button type="text" @click="look(scope.row)">查看</el-button>
								</div>
							</template>
						</el-table-column>
						<template slot="empty">
							<el-empty :image-size="100" description="暂无数据"></el-empty>
						</template>
					</el-table>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import edit from './edit.vue';
export default {
	components: {
		edit,
		smCard
	},
	data() {
		return {
			tableHeight: 0,
			provinces: [],
			activeName: '1',
			options: [
				{
					label:'全部',
					value:0
				},
				{
					label:'诊断',
					value:1
				},
				{
					label:'维修',
					value:2
				}
			],
			tableData: {
				records: []
			},
			searchParm: {
				size: 10,
				current: 1,
				type: '',
				skillType:'',
			},
			showEditDialog: false,
			editObj: {},
			isLoading: false
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		handleChange(value) {
			this.searchParm.type = value;
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 10,
				current: 1,
				type: '',
				skillType:'',
			};
			this.list()
		},
		// 类目一选择
		cskillTypeChange(e) {
			this.searchParm.skillType = e;
			this.list()
		},
		//展示数据
		list() {
			this.$get('/shop-api/mall/expert/list', this.searchParm)
				.then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {
					//请求失败，关闭loading
					this.isLoading = false;
				});
		},
		//在线预览
		preview(row) {
			window.open(this.photoURL + row.url);
		},
		//下架
		remove(row) {
			this.$confirm('是否确认下架？', '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/shop-api/mgmt/goods/sale_off', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},

		//打开查看弹窗
		look(row) {
			this.editObj = {};
			this.editObj['id'] = row.id;
			this.editObj['type'] = 0;
			this.showEditDialog = true;
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		}
	},
	mounted() {
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
		if (this.$route.params.type) {
			this.searchParm.type = this.$route.params.type;
			this.list();
		} else {
			this.list();
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
.goodImg {
	width: 80px;
	height: 80px;
	margin-top: 5px;
}
</style>
